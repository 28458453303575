.pageHeader {
	padding-block: var(--spacing-xl);
	padding-inline: var(--spacing-2xs);
	position: relative;

	&:global(.alignfull) {
		margin-left: var(--margin-full-width);
		margin-right: var(--margin-full-width);
		max-width: var(--screen-width);
	}

	/* Add a border to the bottom of the Page Header */
	&:not(.pageHeaderWithBackground, .pageHeaderIsNotPrimaryHeading, &:global([class*="is-style-pairing-"])) {
		border-bottom: 1px solid var(--color-neutral-dark-20);
	}
}

.pageHeaderWithBackground {
	--color: var(--color-neutral-white);
	--color-hover: var(--color-neutral-white);
	--color-focus: var(--color-neutral-white);
	--color-active: var(--color-neutral-white);
	--outline-color: var(--color-neutral-white);
}

.pageHeaderFigureBackground {
	inset: 0;
	margin: 0;
	position: absolute;
	z-index: 2;
}

.pageHeaderBackground {
	object-fit: cover;
}

.pageHeaderOverlay {
	background-color: var(--color-neutral-dark-40);
	inset: 0;
	position: absolute;
	z-index: 3;
}

.pageHeaderContainer {
	--max-width-wide: 85rem;

	margin-inline: auto;
	max-width: var(--max-width-wide);
	position: relative;
	z-index: 5;
}

.pageHeaderWrapper {
	display: flex;
	flex-direction: column;
	gap: var(--grid-l-gutter);

	@media (--bp-medium) {
		flex-direction: row;
		justify-content: space-between;
	}
}

.pageHeaderContent {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-s);

	@media (--bp-medium) {
		width: 75%;
	}
}

.pageHeaderContentHead {
	display: flex;
	gap: var(--grid-m-gutter);

	@media (--bp-medium) {
		align-items: center;
	}
}

.pageHeaderBrandImage {
	height: 3.75rem;
	object-fit: contain;
	width: 3.75rem;
}

.pageHeaderSponsorContent {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: var(--grid-m-gutter);
}

.pageHeaderSponsorDetails {
	align-items: center;
	column-gap: var(--spacing-3xs);
}

.pageHeaderSponsorName {
	--font-family: var(--typography-caption-family);
	--font-size: var(--typography-caption-size);
	--font-weight: var(--typography-caption-weight);
	--letter-spacing: var(--typography-caption-letter-spacing);
	--line-height: var(--typography-caption-line-height);

	@media (--bp-medium) {
		--font-family: var(--typography-caption-desktop-family);
		--font-size: var(--typography-caption-desktop-size);
		--font-weight: var(--typography-caption-desktop-weight);
		--letter-spacing: var(--typography-caption-desktop-letter-spacing);
		--line-height: var(--typography-caption-desktop-line-height);
	}

	color: var(--color);
	flex: none;
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
}

.pageHeaderTitle {
	--font-family: var(--typography-h-2xl-family);
	--font-size: var(--typography-h-2xl-size);
	--font-weight: var(--typography-h-2xl-weight);
	--letter-spacing: var(--typography-h-2xl-letter-spacing);
	--line-height: var(--typography-h-2xl-line-height);

	@media (--bp-medium) {
		--font-family: var(--typography-h-2xl-desktop-family);
		--font-size: var(--typography-h-2xl-desktop-size);
		--font-weight: var(--typography-h-2xl-desktop-weight);
		--letter-spacing: var(--typography-h-2xl-desktop-letter-spacing);
		--line-height: var(--typography-h-2xl-desktop-line-height);
	}

	color: var(--color);
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
}

.pageHeaderDescription {
	--font-family: var(--typography-body-family);
	--font-size: var(--typography-body-size);
	--font-weight: var(--typography-body-weight);
	--letter-spacing: var(--typography-body-letter-spacing);
	--line-height: var(--typography-body-line-height);

	@media (--bp-medium) {
		--font-family: var(--typography-body-desktop-family);
		--font-size: var(--typography-body-desktop-size);
		--font-weight: var(--typography-body-desktop-weight);
		--letter-spacing: var(--typography-body-desktop-letter-spacing);
		--line-height: var(--typography-body-desktop-line-height);
	}

	color: var(--color);
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
}

.pageHeaderCta {
	flex: none;

	@media (--bp-medium) {
		align-self: flex-end;
	}
}

/* Keep the Page Header text white when it has a background. */
@container style(--is-dark-mode: 1) {

	.pageHeaderWithBackground {
		--color: var(--color-neutral-white);
	}
}

/* Keeps the proper alignment when the block is not .alignfull */
:global(.content) .pageHeader {

	&:not(:global(.alignfull)) {
		padding-inline: 0;
	}
}
